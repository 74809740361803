import React from "react"
import styled from 'styled-components'
import {dark, light} from './styles'

type Props = {
  title?: string,
  imageName?: string,
  type: string,
  width?: string,
  height?: string,
  dark?: boolean,
  footer?: string,
  text?: string,
}

export const ProtoCard = styled.div`
  position: relative;
  z-index: 10;
  font-weight: bold;
  color: ${dark};
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '100%'};
  max-height: 100%;

  outline: ${props => props.outline && '1px solid black'};
  display: grid;
  align-self: center;
  cursor: pointer;
  border-radius: 20px;
  
   box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
    background: linear-gradient(135deg, rgba(230, 232, 250, 0.5), rgba(230, 232, 250, 0.8));
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.45);
    }
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
    color: ${light};
    background: linear-gradient(135deg, rgba(39, 40, 35, 1), rgba(39, 40, 35, 0.9));
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
    }
  }
  
  margin-top: 5%;
`
const PostCard = styled(ProtoCard)`
  grid-template-rows: 30% 55% 15%;
  grid-template-columns: 5% 90% 5%;
`
const ImageCard = styled(ProtoCard)`
  grid-template-rows: 65% 30%;
  padding-top: 1%;
  grid-template-columns: 5% 90% 5%;
  grid-row-gap: 4%;
  max-height: 70%;
`
const CardTitle = styled.div`
  align-self: center;
  font-size: 1.5rem;
  justify-self: start;
  grid-column-start: 2;
`
const PostTitle = styled(CardTitle)`
  grid-row-start: 1;
`
const ImgTitle = styled(CardTitle)`
  text-align: center;
  width: 100%;
  grid-row-start: 2;
  height: 100%;
  font-size: 1.2rem;
  border-top: 1px solid ${dark};
  @media (prefers-color-scheme: dark) {
    border-top: 1px solid ${light};
  }
  padding-top: 10%;
`

const CardText = styled.div`
  width: 100%;
  height: 100%;
  grid-column-start: 2;
  justify-self: start;
  align-self: start;
  grid-row-start: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${dark};
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid ${light};
  }
`
const CardImage = styled.div`
  grid-column-start: 2;
  grid-row-start: 1;
  display: grid;
  align-self: center;
  overflow: hidden;
  height: 90%;
  max-height: 90%;
`
const CardFooter = styled.div`
  grid-column-start: 2;
  justify-self: center;
  align-self: center;
`

const Card: React.FC<Props>= ({title, type, width, height, footer, children}) => {
  if(type === "post") {
    return <PostCard >
      <PostTitle>{title}</PostTitle>
      <CardText>{children}</CardText>
      <CardFooter>{footer}</CardFooter>
    </PostCard>
  } else {
    return <ImageCard >
      <CardImage>{children}</CardImage>
      <ImgTitle>{title}</ImgTitle>
    </ImageCard>
  }
}



export default Card
