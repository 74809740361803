import React from "react"
import styled from "styled-components"
import {dark, highlight, light} from "./styles"
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  style?: object
  handleInput: Function
}

const InputContainer = styled.div`
  background: no-repeat center left 10px url(${props => props.searchImg});
  background-size: 30px;
  width: 100%;
  height: 60px;
  margin-top: 4%;
  border: 5px solid ${highlight};
  font-size: 1.5rem;
  margin-bottom: 4%;
  border-radius: 20px;
   box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
    }
`
const Input = styled.input`
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-left: 45px;
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
  ::placeholder {
    color: ${highlight};
  }
  outline-width: 0;
  border: 0px solid black;
`

const Search: React.FC<Props> = ({style, handleInput}) => {
  const data = useStaticQuery(graphql`
      query {
          searchImg: file(relativePath: { eq: "images/search.png" }) {
              childImageSharp {
                  fixed{
                      src
                  }
              }
          }
      }
  `)
  return <InputContainer style={style} searchImg={data.searchImg.childImageSharp.fixed.src}>
    <Input type={'search'} placeholder={"Search by title, topic, or keyword"} onChange={e => handleInput(e.target.value.toLowerCase())}/>
  </InputContainer>
}

export default Search
