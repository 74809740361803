import React, { useState } from "react"
import Seo from '../components/seo'
import { graphql, Link } from 'gatsby'
import ArticleCard from '../components/articleCard'
import Search from "../components/search"
import parseSearch from "../helpers/search"
import {MainContainer, CardsContainer} from "../components/styles"
import {IdHash} from "../helpers/interfaces"
import { hashImages } from "../helpers/hashFunctions"

type Props = {
  data: any
}

const NewsPage: React.FC<Props> = ({ data }) => {
  const [input, setInput] = useState("");
  const [ids, setIds] = useState<IdHash>({});

  const newsImgHash = hashImages(data.newsImages)

  const handleInput = (searchString: string) => {
    setInput(searchString)
    searchString.length > 0 ? setIds(parseSearch("article", data.news.edges, searchString)) : null
  }

  return <>
    <Seo title="news"/>
    <MainContainer>
      <Search style={{gridColumnStart: 2}} handleInput={handleInput} />
      <CardsContainer rows={data.news.edges.length}>
        {
          data.news.edges.map((edge: any, index: number) => {const { node: {
            id,
            frontmatter: {
              title,
              date,
              summary,
              path,
              imageName,
              author,
              topic,
              keywords
            }
          } } = edge
            if (input.length < 1|| ids && ids[id]) {
              return <Link to={path} style={{ textDecoration: "none", color: "black", gridColumnStart: 1, width: "100%" }}><ArticleCard
                key={index} title={title} footer={`${author} | ${date} | ${topic}`} image={newsImgHash[imageName].fluid} summary={summary}
                keywords={keywords} /></Link>
            }
          })}
      </CardsContainer>
    </MainContainer>
  </>
}

export default NewsPage

export const query = graphql`
    query News {
        news: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 1000
            filter: {
                frontmatter: {type: {eq: "news"}}
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        path
                        type
                        title
                        summary
                        author
                        topic
                        date(formatString: "MMMM DD, YYYY")
                        imageName
                        keywords
                    }
                }
            }
        }
        newsImages: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "newsImages"}}
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`
