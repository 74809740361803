
interface IdHash {
  [key: string]: object;
}
const parseSearch = (type: string, edges: Array<any>, input: string) : object => {
  let ids: IdHash = {};
  let stripped = input.replace(/[^a-z0-9]/gi, "")
  edges.forEach((edge: any) => {
    if(type === "article") {
      const {
        node: {
          id,
          frontmatter: {
            title,
            topic,
            date,
            keywords
          }
        }
      } = edge
      const keywordsMatch = keywords.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(stripped)
      const titleMatch = title.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(stripped)
      const topicMatch = topic.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(stripped)
      const dateMatch = date.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(stripped)


      if (dateMatch || keywordsMatch || titleMatch || topicMatch) {
        ids[id] = id
      }
    } else {
      const {
        node: {
          id,
          project: {
            title
          }
        }
      } = edge

      const titleMatch = title.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(stripped)
      if (titleMatch) {
        ids[id] = id
      }
    }
  })
  return ids
}

export default parseSearch
